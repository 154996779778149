<div class="preview-form">
  <button class="preview-form_close-icon" mat-icon-button (click)="closeDialog()">
    <mat-icon class="button-block_small-icon" svgIcon="close" />
  </button>
  @if (form()) {
    <div class="preview-form_form-name">{{ form().name }}</div>

    <mat-toolbar class="bg-transparent">
      <h5>{{ availablePages()[paginator.pageIndex]?.title }}</h5>
      <div class="ms-auto">Page {{ paginator.pageIndex + 1 }} of {{ availablePages().length }}</div>
      <mat-paginator #paginator [hidePageSize]="true" [length]="availablePages().length" [pageSize]="1" />
    </mat-toolbar>

    <phar-form-page
      class="preview-form_form-page"
      [builderMode]="false"
      [allowSelectEditMode]="false"
      [page]="availablePages()[paginator.pageIndex]"
      [form]="form()" />
  }
</div>
