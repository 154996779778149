import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  Signal,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/models/app.state';
import { resetCurrentForm, searchFormById } from '../store/form.actions';
import { selectCurrentFormStateForm } from '../store/form.state';
import { FormModel, IFormPage } from '../form.model';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'phar-preview-form-questions-dialog',
  templateUrl: 'preview-form-questions-dialog.component.html',
  styleUrls: ['preview-form-questions-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewFormQuestionsDialogComponent implements OnInit, OnDestroy {
  form: Signal<FormModel>;
  availablePages: Signal<IFormPage[]>;
  private readonly injector = inject(Injector);

  constructor(
    private readonly store: Store<AppState>,
    private readonly dialogRef: MatDialogRef<PreviewFormQuestionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data: { form: FormModel },
  ) {}

  ngOnInit(): void {
    this.form = toSignal(this.store.select(selectCurrentFormStateForm), { injector: this.injector });
    this.availablePages = computed(() => {
      return this.form().body.pages?.filter(({ isDeleted }) => !isDeleted) || [];
    });

    this.store.dispatch(searchFormById({ id: this.data.form.id }));
  }

  ngOnDestroy(): void {
    this.store.dispatch(resetCurrentForm({ formType: null }));
  }

  closeDialog(): void {
    this.dialogRef.close(null);
  }
}
